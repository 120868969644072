import React, { FC } from "react";
import { graphql } from "gatsby";

import {
  // MDX,
  // MDXProps,
  // Section,
  // SectionProps,
  ServiceHero,
  ServiceHeroProps,
  ServiceIntro,
  ServiceIntroProps,
} from "components";
import {
  ContactFooter,
  FeaturedAffiliatesSection,
  ProcessSection,
  SEO,
  SEOProps,
  ServicesSection,
} from "containers";
import { DefaultLayout, DefaultLayoutProps } from "layouts";
import { useSiteMetadataQuery } from "graphql";

import { ServiceTemplateProps } from "./types";

const ServiceTemplate: FC<ServiceTemplateProps> = ({
  data: {
    service: {
      body,
      excerpt,
      frontmatter: {
        description,
        hero,
        image,
        introImage,
        layout,
        links,
        // main,
        // mdx,
        page,
        seo,
        showFooter = false,
        showHeader = true,
        showProcess = true,
        showRecommended = true,
        subtitle,
        title,
      },
    },
  },
  location,
  // pageContext,
}) => {
  const { siteUrl } = useSiteMetadataQuery();
  const pageId = page ? `page-${page}` : "page-service";

  const seoProps: SEOProps = {
    title: seo?.title || title,
    description: seo?.description
      ? seo?.description
      : description
      ? description
      : excerpt,
    image: location
      ? image
        ? `${location.origin}${image.childImageSharp?.fluid?.src}`
        : `${location.origin}/logo.png`
      : image
        ? `${siteUrl}${image.childImageSharp?.fluid?.src}`
        : `${siteUrl}/logo.png`,
    url: location?.href,
    ...seo,
  };

  const layoutProps: DefaultLayoutProps = {
    on: pageId,
    header: {
      bgColor: "paper",
      color: "paper",
    },
    themeSwitch: {
      mod: "text-paper hover:text-hover",
    },
    ...layout,
  };

  const heroProps: ServiceHeroProps = {
    on: pageId,
    caption: {
      heading: title,
      subheading: subtitle,
    },
    media: {
      image: {
        ...image,
        name: title,
      },
      ...hero?.media,
    },
    ...hero,
  };

  const featuredAffiliatesSectionProps = {
    on: pageId,
    modifier: "intro service",
  };

  const introProps: ServiceIntroProps = {
    on: pageId,
    content: body,
    image: introImage,
    modifier: !links && "intro service",
  };

  // const mainProps: SectionProps = {
  //   on: pageId,
  //   pattern: "main",
  //   ...main,
  // };

  return (
    <>
      <SEO {...seoProps} />
      <DefaultLayout {...layoutProps}>
        <>
          {showHeader && <ServiceHero {...heroProps} />}
          {links && (
            <FeaturedAffiliatesSection
              featured={links}
              {...featuredAffiliatesSectionProps}
            />
          )}
          <ServiceIntro {...introProps} />
          {/* {body && (
        <Section {...mainProps}>
          <MDX {...(mdx as MDXProps)} body={body} />
        </Section>
      )} */}
          {showProcess && <ProcessSection />}
          {showRecommended && <ServicesSection on={pageId} />}
          {showFooter && <ContactFooter />}
        </>
      </DefaultLayout>
    </>
  );
};

export const serviceBySlugQuery = graphql`
  query ServiceBySlug($slug: String!) {
    service: mdx(slug: { regex: $slug }) {
      body
      excerpt
      frontmatter {
        categories
        description
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        introImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        links
        subtitle
        tags
        title
      }
      slug
    }
  }
`;

export default ServiceTemplate;
